.bg-primary {
  background-color: @primary;
}
.bg-secondary {
  background-color: @secondary;
}
.bg-gray {
  background-color: @gray;
}
.bg-dark-gray {
  background-color: @dark-gray;
}
.bg-primary-wen {
  background-color: @primary-wen;
}
.bg-success {
  background-color: @success;
}
.bg-wen-hot {
  background-color: #f5ecec !important;
}
.bg-white {
  background-color: white;
}
.color-primary {
  color: @primary !important;
}
.color-secondary {
  color: @secondary !important;
}
.color-danger {
  color: @danger !important;
}
.color-white {
  color: white;
}
.p-0 {
  padding: 0 !important;
}
.p-2 {
  padding: 2rem !important;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.p-1 {
  padding: 1rem;
}
.pl-20-create {
  padding-left: 20px;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem;
}

.w-100 {
  width: 100%;
}
.min-vh-100 {
  min-height: 100vh;
}
.min-vh-90 {
  min-height: calc(100vh - 200px);
}
.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify !important;
}
.z-100 {
  position: relative;
  z-index: 100;
}
.center-center {
  display: grid;
  justify-content: center;
  align-content: center;
}
.box {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #0B5580;
}
.no-selected-user {
  user-select: none !important;
}
.iframeTest {
  width: 100%;
  min-height: calc(100vh - 200px);
}
.shadow-sm {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}
.border-radius {
  border-radius: 1rem !important;
}
.btn-border-radius {
  border-radius: 10px !important;
}
.upload-w-full {
  .ant-upload {
    width: 100% !important;
  }
}
.min-w-200 {
  width: 200px !important;
  .ant-upload {
    width: 200px !important;
  }
}
.logo-smp {
  height: 7rem;
}
.input-form {
  border-radius: 0.8rem !important;
  background-color: white !important;
}
.cursor-pointer {
  cursor: pointer;
  user-select: none !important;
}
.active-menu-item {
  transition: all 200ms ease-in-out;
  background-color: #e4f7fd !important;
  color: @primary !important;
  font-weight: bold !important;
}
